<template>
  <div class="h-screen p-5">
    <div class="my-8 flex justify-between items-center">
      <h1 class="font-semibold text-xl text-white">Compare by individual user</h1>
      <select v-if="!isLoading" v-model="selectedUser" class="bg-white p-1">
        <option v-if="users.length === 0" disabled value="">{{ 'No users' }}</option>
        <option v-else v-for="user in users" :key="user.id" :value="user.id">
          {{ user.title }}
        </option>
      </select>
    </div>
    <LocationsTable v-if="!isLoading" :labels="['Assessment', 'Track', 'Task Switch', 'Figure Decide', 'Total']" :bottomArrows="false">
      <template v-slot:table-content>
        <tr v-for="(score, assessment) in userAssessments[selectedUser]" :key="assessment" class="bg-white p-5">
          <td class="text-center">{{ assessment }}</td>
          <td class="text-center">{{ score["Track"] }}</td>
          <td class="text-center">{{ score["Task Switch"] }}</td>
          <td class="text-center">{{ score["Figure Decide"] }}</td>
          <td class="text-center">{{ score["total"] }}</td>
        </tr>
      </template>
    </LocationsTable>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { getUserAssessmentScores } from '@/services/user/user.js';
import LocationsTable from '@/components/location/LocationsTable.vue';

const store = useStore();
const user = computed(() => store.getters['user/getUserData']);

const rawData = ref([]);
const selectedUser = ref('');
const users = ref([]);
const isLoading = ref(true);
const currentDevice = ref('Oculus');

const userAssessments = computed(() => {
  const assessments = {};
  rawData.value.forEach((row, index) => {
    if (index === 0) {
      users.value = row.slice(1).map((user, index) => ({
        ...user,
        index
      }));
      return;
    }
    const assessmentName = row[0];
    row.slice(1).forEach((score, userIndex) => {
      const userId = users.value[userIndex].id;
      if (!assessments[userId]) {
        assessments[userId] = {};
      }
      assessments[userId][assessmentName] = score;
    });
  });
  return assessments;
});

const selectedUserName = computed(() => {
  const user = users.value.find(u => u.id === selectedUser.value);
  return user ? user.title : '';
});

onMounted(async () => {
  await userAssessmentScores();
});

async function userAssessmentScores() {
  isLoading.value = true;
  const data = await getUserAssessmentScores(user.value.location, "Oculus");
  rawData.value = data;
  if (rawData.value.length > 0 && rawData.value[0].length > 1) {
    users.value = rawData.value[0].slice(1);
    selectedUser.value = users.value[0].id;
  }
  isLoading.value = false;
}
</script>
  
<style scoped>
table {
  border: none;
}

th {
  color: #1d2e48;
  background: #ecf0f5;
}

tr {
  border-top: 1px solid #ecf0f5;
  border-bottom: 2px solid #ecf0f5;
}

th,
td {
  border: none;
}

tr td {
  padding: 20px;
}

tr:hover td {
  background: #4e5066;
  color: #ffffff;
}

tr:first-child {
  border-top: none;
}

tr:last-child {
  border-bottom: none;
}
</style>
